import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ModalController } from '@ionic/angular';

export interface Altaformulario{
  altaformulario: string;
}

@Component({
  selector: 'app-popover-formulario',
  templateUrl: './popover-formulario.component.html',
  styleUrls: ['./popover-formulario.component.scss'],
})
export class PopoverFormularioComponent implements OnInit {

  listaEspera = false;
  listaEspera2 = false;
  datoportada = false;

  public colaltaformulario: any;
  public rowsaltaformulario: any;

  constructor(
    private http: HttpClient,
    private modalCtrl: ModalController,
  ) { this.colaltaformulario = [
      {name: 'id', width: 250},
      {name: 'campo', width: 50},
      {name: 'tipo', width: 150},
      {name: 'min', width: 50},
      {name: 'max', width: 300},
      {name: 'link', width: 300}
    ];
    this.http.get<Altaformulario>('../../assets/data/altaformulario.json').subscribe((resp)=>{
      console.log(resp);
      this.rowsaltaformulario = resp.altaformulario;
    });

    }

  segmentChanged(ev: any) {
    console.log('Segment changed', ev);
  }

  ngOnInit() {}
  onClick(){}

  onListaEspera(event: any){
    console.warn(event.detail.checked);
    console.warn(this.listaEspera);
  }
  onListaEspera2(event: any){
    console.warn(event.detail.checked);
    console.warn(this.listaEspera2);
  }
  onDatoportada(event: any){
    console.warn(event.detail.checked);
    console.warn(this.datoportada);
  }
  onCancel(){
    this.modalCtrl.dismiss(null, 'cancel');
  }
}
