import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then( m => m.HomePageModule)
  },
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'alta-cita',
    loadChildren: () => import('./pages/cita/alta-cita/alta-cita.module').then( m => m.AltaCitaPageModule)
  },
  {
    path: 'horarios',
    loadChildren: () => import('./pages/horarios/horarios.module').then( m => m.HorariosPageModule)
  },
  {
    path: 'consulta-citas',
    loadChildren: () => import('./pages/consulta-citas/consulta-citas.module').then( m => m.ConsultaCitasPageModule)
  },
  {
    path: 'abc-pacientes',
    loadChildren: () => import('./pages/abc-pacientes/abc-pacientes.module').then( m => m.AbcPacientesPageModule)
  },
  {
    path: 'abc-productos',
    loadChildren: () => import('./pages/abc-productos/abc-productos.module').then( m => m.AbcProductosPageModule)
  },
  {
    path: 'abc-proveedores',
    loadChildren: () => import('./pages/abc-proveedores/abc-proveedores.module').then( m => m.AbcProveedoresPageModule)
  },
  {
    path: 'abc-pagos',
    loadChildren: () => import('./pages/abc-pagos/abc-pagos.module').then( m => m.AbcPagosPageModule)
  },
  {
    path: 'abc-gastos',
    loadChildren: () => import('./pages/abc-gastos/abc-gastos.module').then( m => m.AbcGastosPageModule)
  },
  {
    path: 'creacion-formularios',
    loadChildren: () => import('./pages/creacion-formularios/creacion-formularios.module').then( m => m.CreacionFormulariosPageModule)
  },
  {
    path: 'charts',
    loadChildren: () => import('./pages/charts/charts.module').then( m => m.ChartsPageModule)

  },  {
    path: 'abc-admin-pagos',
    loadChildren: () => import('./pages/abc-admin-pagos/abc-admin-pagos.module').then( m => m.AbcAdminPagosPageModule)
  },
  {
    path: 'abc-tipo-consulta',
    loadChildren: () => import('./pages/abc-tipo-consulta/abc-tipo-consulta.module').then( m => m.AbcTipoConsultaPageModule)
  },
  {
    path: 'lista-espera',
    loadChildren: () => import('./pages/lista-espera/lista-espera.module').then( m => m.ListaEsperaPageModule)
  },
  {
    path: 'mensaje-corfirmacion',
    loadChildren: () => import('./pages/mensaje-corfirmacion/mensaje-corfirmacion.module').then( m => m.MensajeCorfirmacionPageModule)
  },






];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
