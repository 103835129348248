import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SplitPaneService {

  splitPaneState: boolean;

    constructor() {
        this.splitPaneState = true;
    }

    setSplitPane(state: boolean) {
      this.splitPaneState = state;
    }

    getSplitPane() {
        return this.splitPaneState;
    }

}
