import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-cita',
  templateUrl: './cita.component.html',
  styleUrls: ['./cita.component.scss'],
})
export class CitaComponent implements OnInit {

  @Input() nombre: string;
  @Input() edad: number;
  @Input() hora: string;
  @Input() tipo: string;
  @Input() estatus: string;
  @Input() telefono: string;

  constructor() { }

  ngOnInit() {}

}
