import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-popover-proveedores',
  templateUrl: './popover-proveedores.component.html',
  styleUrls: ['./popover-proveedores.component.scss'],
})
export class PopoverProveedoresComponent implements OnInit {

  constructor(
    private modalCtrl: ModalController,
  ) { }

  ngOnInit() {}
  onClick(){}

  onCancel(){
    this.modalCtrl.dismiss(null, 'cancel');
  }
}
