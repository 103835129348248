import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-horario',
  templateUrl: './horario.component.html',
  styleUrls: ['./horario.component.scss'],
})
export class HorarioComponent implements OnInit {

  @Input() hora: string;
  @Input() checked: boolean;
  @Output() picked = new EventEmitter<string>();

  constructor() { }

  ngOnInit() {

  }

  onClick(){
    this.checked = !this.checked;
    //this.picked.emit(this.fecha);
  }

}
