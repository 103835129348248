import { ModalController } from '@ionic/angular';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-popover-horario-no-disp',
  templateUrl: './popover-horario-no-disp.component.html',
  styleUrls: ['./popover-horario-no-disp.component.scss'],
})
export class PopoverHorarioNoDispComponent implements OnInit {

  constructor(
    private modalCtrl: ModalController
  ) { }

  ngOnInit() {}

  onCancel(){
    this.modalCtrl.dismiss(null, 'cancel');
  }

  onClick(){}

}
