import { Component, OnInit,Input } from '@angular/core';

@Component({
  selector: 'app-cita-small',
  templateUrl: './cita-small.component.html',
  styleUrls: ['./cita-small.component.scss'],
})
export class CitaSmallComponent implements OnInit {
  @Input() nombre: string;
  @Input() hora: string;
  @Input() concepto: string;


  constructor() { }

  ngOnInit() {}

}
