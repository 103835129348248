import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-popover-insumos',
  templateUrl: './popover-insumos.component.html',
  styleUrls: ['./popover-insumos.component.scss'],
})
export class PopoverInsumosComponent implements OnInit {

  constructor(
    private modalCtrl: ModalController,
  ) { }

  ngOnInit() {}
  onClick(){}

  onCancel(){
    this.modalCtrl.dismiss(null, 'cancel');
  }
}
