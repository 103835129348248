import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

interface DiaCalendario{
  dia: number;
  mes: number;
  ano: number;
  diaSemana: number;
  selected: boolean;
  enable: boolean;
}

@Component({
  selector: 'app-calendario',
  templateUrl: './calendario.component.html',
  styleUrls: ['./calendario.component.scss'],
})
export class CalendarioComponent implements OnInit {

  @Input() month: number;
  @Input() year: number;
  @Input() minDate: Date;

  @Input() diasDeshabilitados: Array<string> = ['2021-11-17', '2021-11-25'];

  @Output() selectDate = new EventEmitter<Date>();

  diasCalendario: DiaCalendario[] = [];

  dias: string[] = ['Do', 'Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sa'];
  meses: string[] = [
    'Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio',
    'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'
  ];

  blankSpaces: true;

  weeks: any;


  constructor() {


  }

  ngOnInit() {
    this.month = this.month-1;
    this.getDiasMes();
  }

  getDiasMes(){
    let tmp = 0;
    this.diasCalendario = [];
    for (let index = 1; index <= 31; index++) {
      const dt = new Date(this.year, this.month, index);

      if(dt.getDate() > tmp){
        tmp = dt.getDate();

        if(dt.getDate() === 1){//LLENA LOS DIAS DEL MES PASADO
          for(let y = dt.getDay()-1; y >= 0; y--){
            this.diasCalendario.push({
              dia: 0,
              mes: dt.getMonth()-1,
              ano: dt.getFullYear(),
              diaSemana: y,
              selected: false,
              enable: false});

          }
        }
        this.diasCalendario.push({
          dia: dt.getDate(),
          mes: dt.getMonth(),
          ano: dt.getFullYear(),
          diaSemana: dt.getDay(),
          selected: false,
          enable: (dt >= this.minDate ? true: false)});

      }
    }

    const ldt = this.diasCalendario[this.diasCalendario.length-1];
    for(let y = 0; y < (6-ldt.diaSemana); y++){// LLENA LOS DIAS DEL MES SIGUIENTE
      this.diasCalendario.push({
        dia: 0,
        mes: ldt.mes+1,
        ano: ldt.ano,
        diaSemana: y,
        selected: false,
        enable: false});
    }

    this.disableDayWeek(0);
    this.disableDayWeek(6);
    this.disableDates();
  }

  getDiasPorDiaSemana(diaSemana: number){
    return this.diasCalendario.filter((d)=>d.diaSemana === diaSemana);
  }


  getDiasWeek(week: number){
    const cd: DiaCalendario[] = [];

    const min = (week-1)*7;
    const max = (week*7);

    let count = 0;
    this.diasCalendario.forEach(d => {
      if(count >= min && count < max){
        cd.push(d);
      }
      count++;
    });

    return cd;
  }

  next(){
    this.month++;
    if(this.month === 12){
      this.year++;
      this.month = 0;
    }
    this.getDiasMes();
  }

  back(){
    this.month--;
    if(this.month === -1){
      this.year--;
      this.month = 11;
    }
    this.getDiasMes();
  }

  pickDate(dia: number){
    this.diasCalendario.forEach(d => {
      if(d.dia === dia){
        d.selected = true;
      }
      else{
        d.selected = false;
      }

    });

    this.selectDate.emit(new Date(this.year, (this.month), dia));
  }

  disableDayWeek(diaSemana: number){
    this.diasCalendario.forEach(d => {
      if(d.diaSemana === diaSemana){
        d.enable = false;
      }
    });
  }

  disableDates(){
    this.diasCalendario.forEach(d => {
      this.diasDeshabilitados.forEach(des => {
        const desD = des.split('-');
        if(d.dia === parseInt(desD[2], 10)
        && d.mes === parseInt(desD[1], 10)-1
        && d.ano === parseInt(desD[0], 10)){
          d.enable = false;
        }
      });
    });
  }








}
