import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-dia',
  templateUrl: './dia.component.html',
  styleUrls: ['./dia.component.scss'],
})
export class DiaComponent implements OnInit {

  @Input() fecha: string;
  @Input() checked: boolean;
  @Output() picked = new EventEmitter<string>();

  mes: string;
  dia: string;
  diaLetra: string;

  dias: string[] = ['Lunes', 'Martes', 'Miercoles', 'Jueves', 'Viernes', 'Sabado', 'Domingo'];
  meses: string[] = [
    'Ene.', 'Feb.', 'Mar.', 'Abr.', 'May.', 'Jun.',
    'Jul.', 'Ago.', 'Sept.', 'Oct.', 'Nov.', 'Dic.'
  ];

  constructor() { }

  ngOnInit() {

    console.warn(this.fecha);

    const dt = this.fecha.split('T')[0].split('-');
    const m = parseInt(dt[1].toString(), 10);
    const d = new Date(this.fecha).getDay();

    console.warn(d);

    this.mes = this.meses[m-1];
    this.dia = dt[2];
    this.diaLetra = this.dias[d];
  }

  onClick(){
    this.checked = !this.checked;
    this.picked.emit(this.fecha);
  }

}
