import { PopoverHorarioNoDispComponent } from './popover-horario-no-disp/popover-horario-no-disp.component';
import { PopoverHorarioComponent } from './popover-horario/popover-horario.component';
import { RouterModule } from '@angular/router';
import { SideMenuComponent } from './side-menu/side-menu.component';
import { CitaSmallComponent } from './cita-small/cita-small.component';
import { PopoverFormularioComponent } from './popover-formulario/popover-formulario.component';
import { PopoverGastosComponent } from './popover-gastos/popover-gastos.component';
import { PopoverProveedoresComponent } from './popover-proveedores/popover-proveedores.component';
import { PopoverServiciosComponent } from './popover-servicios/popover-servicios.component';
import { PopoverInsumosComponent } from './popover-insumos/popover-insumos.component';
import { PopoverProductosComponent } from './popover-productos/popover-productos.component';
import { CitaComponent } from './cita/cita.component';
import { HorarioComponent } from './horario/horario.component';
import { DiaComponent } from './dia/dia.component';
import { IonicModule } from '@ionic/angular';
import { FormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CalendarioComponent } from './calendario/calendario.component';
import { HttpClientModule } from '@angular/common/http';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';



@NgModule({
  declarations: [
    CalendarioComponent,
    DiaComponent,
    HorarioComponent,
    CitaComponent,
    PopoverProductosComponent,
    PopoverInsumosComponent,
    PopoverServiciosComponent,
    PopoverProveedoresComponent,
    PopoverFormularioComponent,
    PopoverGastosComponent,
    CitaSmallComponent,
    SideMenuComponent,
    PopoverHorarioComponent,
    PopoverHorarioNoDispComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    HttpClientModule,
    NgxDatatableModule,
    RouterModule
  ],
  exports: [
    CalendarioComponent,
    DiaComponent,
    HorarioComponent,
    CitaComponent,
    PopoverProductosComponent,
    PopoverInsumosComponent,
    PopoverServiciosComponent,
    PopoverProveedoresComponent,
    PopoverFormularioComponent,
    PopoverGastosComponent,
    CitaSmallComponent,
    SideMenuComponent,
    PopoverHorarioComponent,
    PopoverHorarioNoDispComponent
  ]
})
export class ComponentsModule { }
