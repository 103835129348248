import { SplitPaneService } from './../../services/split-pane.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MenuController } from '@ionic/angular';

interface NavOption{
  text: string;
  route: string;
}


@Component({
  selector: 'app-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.scss'],
})
export class SideMenuComponent implements OnInit {

  routerLinkActive;

  isVisible = true;

  navOptions: NavOption[] = [
    {
      text: 'Alta de cita',
      route: '/alta-cita',
    },
    {
      text: 'Horarios',
      route: '/horarios'
    },
    {
      text: 'Citas',
      route: '/consulta-citas'
    },
    {
      text: 'Lista de espera',
      route: '/lista-espera'
    },
    {
      text: 'Mensaje Confirmación',
      route: '/mensaje-corfirmacion'
    },
    /*{
      text: 'Tipo de Consulta',
      route: '/abc-tipo-consulta'
    },*/
    {
      text: 'Pacientes',
      route: '/abc-pacientes'
    },
    {
      text: 'Productos',
      route: '/abc-productos'
    },
    {
      text: 'Proveedores',
      route: '/abc-proveedores'
    },
    {
      text: 'Balance',
      route: '/abc-pagos'
    },
    {
      text: 'Admin Pagos',
      route: '/abc-admin-pagos'
    },
    {
      text: 'Gastos',
      route: '/abc-gastos'
    },
    {
      text: 'Formularios',
      route: '/creacion-formularios'
    }
  ];


  constructor(
    private router: Router,
    private splitPaneService: SplitPaneService,
    private menuCtrllr: MenuController) { }

  ngOnInit() {
    this.isVisible = this.splitPaneService.getSplitPane();
  }

  onClick(){
    this.menuCtrllr.close();
  }

}
