import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-popover-horario',
  templateUrl: './popover-horario.component.html',
  styleUrls: ['./popover-horario.component.scss'],
})
export class PopoverHorarioComponent implements OnInit {

  constructor(
    private modalCtrl: ModalController,
  ) { }

  ngOnInit() {}

  onCancel(){
    this.modalCtrl.dismiss(null, 'cancel');
  }

  onClick(){}

}
